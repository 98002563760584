import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Section from '@/components/common/Section'
import ScopeChart from './ScopeChart'

const DevScope = props => (
  <Root theme={props.theme} className={props.className}>
    <Main>
      <Left>
        <Section
          subtitle="OUR SERVICE"
          title={(
            <Title>私たちが提供するのは、<br />プログラムではなく、仕組みです。</Title>
          )}
          body={(
            <Fragment>単なる技術の提供ではなく、システム構築を通してお客様のビジネスに貢献することが私たちの仕事です。要求の中にある本質を見つけ、課題解決のための最適な道筋をシステムに落とし込みます。</Fragment>
          )}
          lang={{ subtitle: 'base' }}
        />
      </Left>
      <Right>
        <Chart />
      </Right>
    </Main>
  </Root>
)

const Root = styled.div`
`

const Main = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    align-items: center;
  }
`

const Left = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
  }
`

const Right = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
  }
  @media ${mq.and(tabletS)} {
    margin-top: 60px;
  }
  @media ${mq.and(mobile)} {
    margin-top: 40px;
  }
`

const Title = styled.span`
  @media ${mq.and(mobileS)} {
    br {
      display: none;
    }
  }
`

const Chart = styled(ScopeChart)`
  display: block;
  @media ${mq.and(desktop)} {
    margin-left: 48px;
    width: calc(100% - 32px);
  }
  @media ${mq.and(laptop)} {
    margin-left: 32px;
    width: calc(100% - 24px);
  }
  @media ${mq.and(tabletL)} {
    margin-left: 32px;
    width: calc(100% - 24px);
  }
  @media ${mq.and(tabletS)} {
    max-width: 640px;
    margin: 0 auto;
  }
`

export default ThemeConsumer(DevScope)
