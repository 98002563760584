import React from 'react'
import styled from '@emotion/styled'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const DevScopeChart = props => (
  <Root {...props}>
    <Svg width="550" height="280" viewBox="0 0 550 280" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="148.076" y="58.3334" width="105.769" height="46.6667" rx="8" fill="#6087FF"/>
      <rect x="148.076" y="175" width="105.769" height="46.6667" rx="8" fill="#D1D1DB"/>
      <rect y="116.667" width="105.769" height="46.6667" rx="8" fill="#6087FF"/>
      <rect x="296.154" y="116.667" width="105.769" height="46.6667" rx="8" fill="#D1D1DB"/>
      <rect x="444.231" y="175" width="105.769" height="46.6667" rx="8" fill="#D1D1DB"/>
      <rect x="296.154" y="233.333" width="105.769" height="46.6667" rx="8" fill="#6087FF"/>
      <rect x="444.231" width="105.769" height="46.6667" rx="8" fill="#0033A1"/>
      <path d="M401.922 256.667H409.855C417.126 256.667 423.076 250.104 423.076 242.083V212.917C423.076 204.896 429.025 198.333 436.297 198.333H444.23" stroke="#9695AC" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M148.076 198.333H140.143C132.872 198.333 126.922 191.771 126.922 183.75V154.583C126.922 146.562 120.973 140 113.701 140H105.769" stroke="#9695AC" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M253.845 198.333H261.778C269.05 198.333 274.999 191.771 274.999 183.75V154.583C274.999 146.562 280.949 140 288.22 140H296.153" stroke="#9695AC" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M296.154 256.667H288.221C280.949 256.667 275 250.104 275 242.083V212.917C275 204.896 269.05 198.333 261.779 198.333H253.846" stroke="#9695AC" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M401.922 140H409.855C417.126 140 423.076 133.437 423.076 125.417V37.9166C423.076 29.8958 429.025 23.3333 436.297 23.3333H444.23" stroke="#9695AC" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M444.231 198.333H436.298C429.027 198.333 423.077 191.771 423.077 183.75V154.583C423.077 146.562 417.128 140 409.856 140H401.923" stroke="#9695AC" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M296.154 140H288.221C280.949 140 275 133.437 275 125.417V96.25C275 88.2291 269.05 81.6666 261.779 81.6666H253.846" stroke="#9695AC" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M105.769 140H113.701C120.973 140 126.922 133.437 126.922 125.417V96.25C126.922 88.2291 132.872 81.6666 140.144 81.6666H148.076" stroke="#6087FF" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M296.154 256.667H288.221C280.949 256.667 275 250.104 275 242.083V96.25C275 88.2291 269.05 81.6666 261.779 81.6666H253.846" stroke="#6087FF" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M401.922 256.667H409.855C417.126 256.667 423.076 250.104 423.076 242.083V37.9166C423.076 29.8958 429.025 23.3333 436.297 23.3333H444.23" stroke="#6087FF" strokeWidth="3" strokeMiterlimit="10"/>
    </Svg>
  </Root>
)

const Root = styled.div`
  ${aspectRatio(550, 280)};
`

const Svg = styled.svg`
  ${aspectRatioChild};
`

export default DevScopeChart
