import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import LD from '@/components/head/linking-data'
import LDEntity from '@/utils/linking-data'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-services.png'
import MainMessage from '@/components/common/MainMessage'
import Scope from '@/components/modules/Service/Dev/Scope'
import Stack from '@/components/modules/Service/Dev/Stack'
import Process from '@/components/modules/Service/Dev/Process'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'
import RecruitLead from '@/components/modules/Recruit/Lead'

const ServicesDevIndexPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const siteMeta = data.site.siteMetadata
      const company = data.company.edges[0].node
      return (
        <Root>
          <SEO location={props.location} title="受託開発事業" description="株式会社ウィザードは、創業から20年間に渡り受託開発事業を続けています。課題と制約を理解して計画と設計を作る力、最適な方法での開発を実現する技術力、丁寧かつ迅速に案件をリードするマネジメント・コミュニケーション能力。システム開発に必要な3つの要素を兼ね備えたウィザードだからこそ、お客様に貢献するシステムを開発することができます。"/>
          <LD json={{
            '@type': 'Service',
            'name': '受託開発事業',
            'description': '株式会社ウィザードは、創業から20年間に渡り受託開発事業を続けています。課題と制約を理解して計画と設計を作る力、最適な方法での開発を実現する技術力、丁寧かつ迅速に案件をリードするマネジメント・コミュニケーション能力。システム開発に必要な3つの要素を兼ね備えたウィザードだからこそ、お客様に貢献するシステムを開発することができます。',
            'provider': LDEntity.Corporation(siteMeta, company)
          }}/>
          <Head>
            <PageTitle src={imgTitle}>受託開発事業</PageTitle>
            <MainMessage
              title={(
                <Fragment>最適なシステムを<br className="title" />最適な方法で</Fragment>
              )}
              body={(
                <Fragment>課題と制約を理解して計画と設計を作る力、最適な方法での開発を実現する技術力、丁寧かつ迅速に案件をリードするマネジメント・コミュニケーション能力。システム開発に必要な3つの要素を兼ね備えたウィザードだからこそ、お客様に貢献するシステムを開発することができます。</Fragment>
              )}
              css={styles.MainMessage}
            />
          </Head>
          <Main>
            <Row css={styles.Scope}><Scope /></Row>
            <Row><Stack /></Row>
            <Row><Process /></Row>
            <Row css={styles.Breadcrumb}><Breadcrumb path={props.location.pathname}/></Row>
            <Row css={styles.Contact}><ContactLead /></Row>
            <Row css={styles.Recruit}><RecruitLead /></Row>
          </Main>
        </Root>
      )
    }}
  />
)

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  position: relative;
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const styles = {
  MainMessage: css`
    br.title {
      display: none;
    }
    @media ${mq.and(mobile)} {
      br.title {
        display: initial;
      }
    }
  `,
  Scope: css`
    margin-top: -40px;
    @media ${mq.and(mobile)} {
      margin-top: -20px;
    }
  `,
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Recruit: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `
}

export default PageBase(ServicesDevIndexPage)

const query = graphql`
  query ServicesDevPageQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
      }
    }
    company: allCompanyInfoYaml {
      edges {
        node {
          name
          legalName
          telephone
          email
          address {
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
          }
        }
      }
    }
  }
`
