import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Section from '@/components/common/Section'
import ParticleBackground from '@/components/common/ParticleBackground'
import Step from './ProcessStep'

const DevProcess = props => (
  <Root>
    <Head>
      <Section
        subtitle="PROCESS"
        title={(
          <Fragment>決めるべきことはしっかり決めます。変えるべきことは柔軟に変えます。</Fragment>
        )}
        body={(
          <Fragment>どんなに時間をかけて計画や設計をしても、プロジェクト進行時には思いもよらない問題が数多く起こります。ウィザードは、限られた時間や予算の中でお客様の要求を満たせるように工夫を凝らし、品質・コスト・納期のバランスの取れたサービスを提供することを心がけています。</Fragment>
        )}
        lang={{ subtitle: 'base' }}
        styles={styles.Section}
      />
    </Head>
    <Main>
      <Background scale={400} />
      <Steps>
        <Step
          title="要求分析"
          icon="planning"
          tags={['基本要件の抽出', 'システム概要書の作成', '開発費用の算定']}
          type="light">
          ご要望のシステムについてのヒアリングや調査を行い、システムが必要な機能要件を抽出し、全体像を整理し概要書としてまとめます。概要書から開発費用をお見積りします。
        </Step>
        <Step
          title="契約"
          icon="contract"
          type="dark">
          お見積り内容に合意頂けましたらご契約頂きます。
        </Step>
        <Step
          title="設計･開発"
          icon="development"
          tags={['設計書の作成', 'プログラム作成', 'テスト']}
          type="light">
          システムに必要な各機能の詳細を整理して各種設計書としてまとめます。設計に合意しましたら各種設計書を元にプログラムを開発します。プログラム開発後は、テストと不具合を修正を行い、品質の向上させます。
        </Step>
        <Step
          title="導入"
          icon="release"
          tags={['リリース/公開作業', '初期運用支援']}
          type="light">
          ご指定の環境にシステムを導入します。導入初期は、業務に支障が出ないよう、システム稼働状況の監視や運用サポートを行います。
        </Step>
        <Step
          title="保守"
          icon="support"
          tags={['機能改善', '障害対応']}
          type="light">
          完成後にもシステムの不具合等が生じることがあります。システムの稼動が軌道に乗るまでトラブルを解消して行きます。また、稼動後の機能追加や修正等のカスタマイズ（別途費用）も行います。
        </Step>
      </Steps>
    </Main>
  </Root>
)

export default ThemeConsumer(DevProcess)

const Root = styled.div``

const Background = styled(ParticleBackground)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Head = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    align-items: center;
  }
`

const Main = styled.div`
  margin-top: 50px;
  position: relative;
  padding: 64px 96px;
  @media ${mq.and(tabletL)} {
    padding: 48px 32px;
  }
  @media ${mq.and(tabletS)} {
    padding: 32px 24px;
  }
  @media ${mq.and(mobile)} {
    padding: 32px 16px;
  }
`

const Steps = styled.ol`
  position: relative;
`

const styles = {
  Section: {
    root: css`
      width: 100%;
    `,
    main: css`
      @media ${mq.and(desktop, laptop, tabletL)} {
        width: 100%;
        margin-top: 16px;
        display: flex;
      }
    `,
    title: css`
      @media ${mq.and(desktop, laptop, tabletL)} {
        flex-grow: 0;
        flex-shrink: 0;
        margin-top: 0;
        width: 50%;
        padding-right: 40px;
      }
    `,
    body: css`
      @media ${mq.and(desktop, laptop, tabletL)} {
        flex-grow: 0;
        flex-shrink: 0;
        margin-top: 0;
        width: 50%;
      }
    `
  }
}
