import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Section from '@/components/common/Section'
import StackIcons from './StackIcons'
import LinkButton1 from '@/components/common/LinkButton1'

const DevStack = props => (
  <Root theme={props.theme} className={props.className}>
    <Main>
      <Left>
        <Icons />
      </Left>
      <Right>
        <Section
          subtitle="EXPERIENCE"
          title={(
            <Fragment>受託開発だからこそ、<br />集約されたノウハウがあります。</Fragment>
          )}
          body={(
            <Fragment>ウィザードは様々な業種のシステムの開発に携わる中で、多くの技術に触れてきました。そのため、お客様の課題、予算、環境に合わせた最適な技術選定が可能です。また、要件定義から保守に至るまでの各フェーズにおける豊富なノウハウ、スキルを保有しているため、プロジェクトを円滑に進めることができます。</Fragment>
          )}
          lang={{ subtitle: 'base' }}
        />
        <Button1 component={Link} to="/projects/" scale="800">実績ページへ</Button1>
      </Right>
    </Main>
    <Foot>
      <Button2 component={Link} to="/projects/" scale="800">実績ページへ</Button2>
    </Foot>
  </Root>
)

const Root = styled.div`
`

const Main = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    align-items: center;
  }
  @media ${mq.and(tabletS, mobile)} {
    display: flex;
    flex-direction: column-reverse;
  }
`

const Left = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
  }
  @media ${mq.and(tabletS)} {
    margin-top: 32px;
  }
  @media ${mq.and(mobile)} {
    margin-top: 24px;
  }
`

const Right = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
  }
`

const Icons = styled(StackIcons)`
  display: block;
  @media ${mq.and(desktop, laptop)} {
    margin-left: -120px;
    width: calc(100% + 100px);
  }
  @media ${mq.and(tabletL)} {
    margin-left: -60px;
    width: calc(100% + 40px);
  }
  @media ${mq.and(tabletS)} {
    max-width: 780px;
    margin: 0 auto;
  }
  @media ${mq.and(mobileS)} {
    margin-left: 0;
    width: 100%;
  }
`

const Foot = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: none;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 56px;
    text-align: center;
  }
`

const Button1 = styled(LinkButton1)`
  margin-top: 64px;
  width: 278px;
  @media ${mq.and(tabletS, mobile)} {
    display: none;
  }
`

const Button2 = styled(LinkButton1)`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: none;
  }
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

export default ThemeConsumer(DevStack)
