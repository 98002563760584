import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import Icon from '@/components/common/Icon'

const ProcessStep = props => {
  const { title, icon, children, ...others } = props
  return (
    <Root {...others}>
      <Container type={props.type} theme={props.theme}>
        <Head>
          <Title>
            <Title_Icon type={props.type} theme={props.theme}><Icon name={icon} /></Title_Icon>
            <Title_Text variant="h3" component="h4">{title}</Title_Text>
          </Title>
          {props.tags && props.tags.length && (
            <Tags>
              {props.tags.map((tag, index) => (
                <Tag key={index} theme={props.theme}>
                  <Tag_Text variant="caption2" color="primary">{tag}</Tag_Text>
                </Tag>
              ))}
            </Tags>
          )}
        </Head>
        <Main>
          <Typography variant="body2">{children}</Typography>
        </Main>
      </Container>
      <Arrow>
        <svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <Arrow_Path type={props.type} theme={props.theme} d="M30.4056 -0.000244141L15.2028 15.2026L0 -0.000244141H30.4056Z"/>
        </svg>
      </Arrow>
    </Root>
  )
}

const getBackgroundColor = props => {
  if (props.type === 'light') {
    return props.theme.scale[500]
  } else {
    return props.theme.scale[800]
  }
}

const getColor = props => {
  if (props.type === 'light') {
    return props.theme.scale.contrast[500]
  } else {
    return props.theme.scale.contrast[800]
  }
}

const getIconBackgroundColor = props => {
  if (props.type === 'light') {
    return props.theme.scale[50]
  } else {
    return props.theme.scale[900]
  }
}

const getIconColor = props => {
  if (props.type === 'light') {
    return props.theme.scale.contrast[50]
  } else {
    return props.theme.scale.contrast[900]
  }
}

const Container = styled.div`
  background-color: ${getBackgroundColor};
  color: ${getColor};
  padding: 32px;
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
  }
  @media ${mq.and(tabletS)} {
    padding: 32px 24px;
  }
  @media ${mq.and(mobile)} {
    padding: 24px 16px;
  }
`

const Head = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    flex-shrink: 0;
    flex-grow: 0;
    width: 50%;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
`

const Title_Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${getIconBackgroundColor};
  color: ${getIconColor};
  font-size: 24px;
`

const Title_Text = styled(Typography)`
  margin-left: 28px;
  @media ${mq.and(mobile)} {
    margin-left: 14px;
  }
`

const Main = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    flex-shrink: 0;
    flex-grow: 0;
    width: 50%;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 18px;
  }
`

const Arrow = styled.div`
  font-size: 0;
  text-align: center;
`

const Arrow_Path = styled.path`
  fill: ${getBackgroundColor};
`

const Root = styled.li`
  &:last-of-type ${Arrow} {
    display: none;
  }
`

const Tags = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 12px;
  }
`

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-right: 8px;
  padding: 0 24px;
  height: 24px;
  border-radius: 10px;
  background-color: ${props => props.theme.background.contrast};
  @media ${mq.and(mobile)} {
    padding: 0 16px;
  }
`

const Tag_Text = styled(Typography)``

export default ThemeConsumer(ProcessStep)
